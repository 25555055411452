<script setup>
import PublicLayout from '@/Layouts/PublicLayout.vue';
import { useI18n } from 'vue-i18n';
import { computed } from '@vue/reactivity';
const route = inject('route');
const i18n = useI18n();


// Components
import ContentGradient from '@/Components/Content/ContentGradient.vue';
import CounterWrapper from '@/Components/Counter/CounterWrapper.vue';
import CtaBar from '@/Components/Cta/CtaBar.vue';
import CtaMockup from '@/Components/Cta/CtaMockup.vue';
import FormNewsletter from '@/Components/Form/FormNewsletter.vue';
import ContactForm from '@/Components/Forms/Contact.vue';
import LandingspageBanner from '@/Components/Landingspage/LandingspageBanner.vue';
import LandingspageUsps from '@/Components/Landingspage/LandingspageUsps.vue';
import ReviewRepeater from '@/Components/Review/ReviewRepeater.vue';
import BlogRecent from '@/Components/Blog/BlogRecent.vue';
import SignUp from '@/Components/SignUp/SignUp.vue';

import LandingspageContent from '@/Components/Landingspage/LandingspageContent.vue';
import PageBlockStep from '@/Components/Steps/PageBlockStep.vue';
import FloatingCta from '@/Components/FloatingCta.vue';
import { usePage } from '@inertiajs/vue3';
import { inject } from 'vue';

const props = defineProps({
    page: Object,
    blogArticles: {
        type: Array,
        required: true,
    },
});

const { t } = useI18n();

const currentPage = usePage().props.route.current;

const isEmployerPage = computed(
    () => !!currentPage.includes(route('public.pages.show', { page: i18n.t('employers') })) || !!currentPage.includes(route('public.pages.show', { page: i18n.t('employers-alt')  }))
);

</script>

<template>
    <PublicLayout :title="page.seo_title" :description="page.description" :pageTemplate="page.template">

        <FloatingCta v-if='isEmployerPage' class='hidden lg:flex' :title="$t('Create an account without obligation')" link="#signup" :button-text="$t('Create account')">
        </FloatingCta>

        <FloatingCta class='flex lg:hidden' :title="$t('Create an account without obligation')" link="https://dl.flexable.nl/appdownload" :button-text="$t('Download the app')" dontHide>
        </FloatingCta>


        <template v-for="block in page.blocks" :key="block.id">
            <LandingspageBanner
                v-if="block.component_name == 'landingspage-banner'"
                v-bind="block.content"
                :media="block.media"
            />

            <LandingspageUsps
                v-if="block.component_name == 'landingspage-usps'"
                v-bind="block.content"
                :media="block.media"
            />

            <SignUp v-if="block.component_name == 'sign-up'" v-bind="block.content" :media="block.media" />

            <ContentGradient
                v-if="block.component_name == 'landingspage-gradient-text'"
                v-bind="block.content"
                :media="block.media"
            />

            <PageBlockStep
                      v-if="block.component_name == 'steps'"
                      :block="block"
            />

            <CtaMockup v-if="block.component_name == 'cta-mockup'" v-bind="block.content" :media="block.media" />

            <CounterWrapper v-if="block.component_name == 'counter'" v-bind="block.content" :media="block.media" />

            <FormNewsletter v-if="block.component_name == 'newsletter'" v-bind="block.content" :media="block.media" />

            <ReviewRepeater
                v-if="block.component_name == 'review-repeater'"
                v-bind="block.content"
                :media="block.media"
            />

            <LandingspageContent
                v-if="block.component_name == 'landingspage-content'"
                v-bind="block.content"
                :media="block.media"
            />

            <BlogRecent
                :blog-articles="blogArticles"
                v-if="block.component_name == 'blog-recent'"
                v-bind="block.content"
                :media="block.media"
            />

            <CtaBar v-if="block.component_name == 'cta-bar'" v-bind="block.content" :media="block.media" />

            <ContactForm v-if="block.component_name == 'contact-form'" v-bind="block.content" :media="block.media" />
        </template>
    </PublicLayout>
</template>
